import React from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import { updateToSignupOption } from 'actions/signupActions'

import { Layout, SEO, CommonButton } from 'components'
import { SIGNUP_ENDPOINT } from 'constants/apiUrls'
import {
  BUTTON_STYLE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from 'constants/buttonConstants'

const useStyles = makeStyles(theme => ({
  center: {
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    paddingTop: '50px',
    width: '1050px',
    margin: '0 auto',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '20px',
      width: '280px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px',
      width: '320px',
    },
    '@media (min-width: 768px)': {
      paddingTop: '20px',
      width: '690px',
    },
    '@media (min-width: 1200px) ': {
      paddingTop: '20px',
      width: '1050px',
    },
  },
}))

const EarlyAdopterProgramPage = () => {
  const classes = useStyles()
  const { user: loggedUser } = useSelector(state => state.userState)

  return (
    <Layout>
      <div className={classes.mainContainer}>
        <SEO title="Early Adopter Program" />

        <div className={classes.center}>
          <StaticImage
            src={`../assets/eap_images/imageEAP.png`}
            alt="Smthy Early Adopter Program"
          />
        </div>

        {loggedUser && loggedUser.isAnonymous && (
          <div className={classes.center}>
            <CommonButton
              buttonStyle={BUTTON_STYLE.ACCENT}
              fullWidth={false}
              label="Sign up now!"
              size={BUTTON_SIZE.SMALL}
              type={BUTTON_TYPE.SUBMIT}
              onClick={() => {
                updateToSignupOption()
                navigate(SIGNUP_ENDPOINT)
              }}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default EarlyAdopterProgramPage
